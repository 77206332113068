import React, { useEffect, useState } from "react";
import { CiCircleMinus, CiCirclePlus } from "react-icons/ci";
import { FaStar } from "react-icons/fa";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation, Autoplay } from "swiper/modules";
import { useForm } from "react-hook-form";
import DOMPurify from "dompurify";
import {
  useAddToCartMutation,
  useGetAllCategoriesQuery,
  useGetCartProductsQuery,
  useGetGeneralDataQuery,
  useGetSpecificProductByIdQuery,
  useGetTotalCartQuery,
} from "../redux/feature/slice/service-api";
import QuickOrder from "../components/quickOrder";
import CategoriesDropdownSkeletonLoader from "../components/loading/categoriesDropdownSkeletonLoader";
import Title from "../components/helmet/title";

const SingleProduct = () => {
  let dataLayer;
  const [ip, setIp] = useState();
  const { register, handleSubmit, watch, setValue, reset, getValues } =
    useForm();

  const selectedColor = watch("colors");
  const selectedSize = watch("sizes");

  const { id } = useParams();

  const [isOpenDescription, setIsOpenDescription] = useState(true);
  const [isOpenReview, setIsOpenReview] = useState(false);
  const [isOpenPolicy, setIsOpenPolicy] = useState(false);
  const [isOpenVideo, setIsOpenVideo] = useState(false);
  let [quantity, setQuantity] = useState(1);

  const tabButtonHandler = (val) => {
    if (val === "description") {
      setIsOpenDescription(true);
      setIsOpenPolicy(false);
      setIsOpenReview(false);
      setIsOpenVideo(false);
    } else if (val === "review") {
      setIsOpenDescription(false);
      setIsOpenPolicy(false);
      setIsOpenReview(true);
      setIsOpenVideo(false);
    } else if (val === "video") {
      setIsOpenDescription(false);
      setIsOpenPolicy(false);
      setIsOpenReview(false);
      setIsOpenVideo(true);
    } else {
      setIsOpenDescription(false);
      setIsOpenPolicy(true);
      setIsOpenReview(false);
      setIsOpenVideo(false);
    }
  };

  const increaseQuantity = () => {
    if (quantity < 10) {
      let q = quantity + 1;
      setQuantity(q);
      setValue("qty", q);
    }
  };

  const decreaseQuantity = () => {
    if (quantity > 1) {
      let q = quantity - 1;
      setQuantity(q);
      setValue("qty", q);
    }
  };

  const { data: product } = useGetSpecificProductByIdQuery(id);
  const [addToCart] = useAddToCartMutation();
  const { data: Categories, isLoading: isCategoryLoading } =
    useGetAllCategoriesQuery();
  const { refetch } = useGetTotalCartQuery(ip);
  const { refetch: refetchCart } = useGetCartProductsQuery(ip);
  const { data } = useGetGeneralDataQuery();
  useEffect(() => {
    window.scrollTo(0, 0);

    const fetchIpAddress = async () => {
      try {
        const response = await axios.get("https://api.ipify.org?format=json");
        setIp(response.data.ip);
      } catch (error) {
        console.error("Error fetching IP address:", error);
        setIp("127.0.0.1");
      }
    };
    fetchIpAddress();
  }, [id]);

    //Datalayer Code...
    useEffect(() => {
      if (product) {
        const productData = product?.data?.product;
        const product_name = productData?.name;
        const product_id = productData?.id;
        const price = productData?.discount_price || productData?.regular_price;
        const category = productData?.category?.name || "Unknown";
        
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({ ecommerce: null });
        window.dataLayer.push({
          event: "view_item",
          ecommerce: {
            currency: "BDT",
            value: price,
            items: [{
              item_name: product_name,
              item_id: product_id,
              price: price,
              item_brand: "Unknown",
              item_category: category,
              item_variant: "",
              item_list_name: "",
              item_list_id: "",
              index: 0,
              quantity: 1,
            }]
          }
        });
      }
    }, [product]);
    //Datalayer Code...

  const ImageArray = product?.data?.product?.product_images;
  const colorsArr = product?.data?.product?.colors;
  const sizeArr = product?.data?.product?.sizes;
  const description = DOMPurify.sanitize(
    product?.data?.product?.long_description
  );
  const policy = DOMPurify.sanitize(product?.data?.product?.policy);

  const addToCartHandler = async (data) => {
    // Calculate the price based on whether there's a discount or not
    let price =
      product?.data?.product?.discount_price ||
      product?.data?.product?.regular_price;
    const qty = data.qty;
    const size = selectedSize;
    const color = selectedColor;

    // Fetch the IP address dynamically
    const fetchIpAddress = async () => {
      try {
        const response = await axios.get("https://api.ipify.org?format=json");
        return response.data.ip;
      } catch (error) {
        console.error("Error fetching IP address:", error);
        return "127.0.0.1";
      }
    };

    const ip_address = await fetchIpAddress();

    const payload = {
      ip_address,
      qty,
      price,
      color,
      size,
    };

    try {
      const response = await addToCart({ id, payload });
      swal("Good job!", response?.data?.message, "success");
      reset();
      refetch();
      refetchCart();
    } catch (error) {
      console.error("Error adding to cart:", error);
    }
  };

  const onSubmit = (data) => {
    addToCartHandler(data);

        //Datalayer Code...
        const productData = product?.data?.product;
        const product_name = productData?.name;
        const product_id = productData?.id;
        const price = productData?.discount_price || productData?.regular_price;
        const category = productData?.category?.name || "Unknown";
    
        dataLayer = window.dataLayer || [];
        dataLayer.push({ ecommerce: null });
        dataLayer.push({
          event: "add_to_cart",
          ecommerce: {
            currency: "BDT",
            value: price,
              items: [{
                  item_name: product_name,
                  item_id: product_id,
                  price: price,
                  item_brand: "Unknown",
                  item_category: category,
                  item_variant: "",
                  item_list_name: "",
                  item_list_id: "",
                  index: 0,
                  quantity: 1,
              }]
          }
        });
      //Datalayer Code...
  };

  const quickData = getValues();

  const [visibleDropdown, setVisibleDropdown] = useState(null);

  const showDropdown = (categoryName) => {
    setVisibleDropdown(categoryName);
  };

  const hideDropdown = () => {
    setVisibleDropdown(null);
  };

  return (
    <div className="overflow-x-hidden mt-14 md:mt-0">
      <Title
        title={`${product?.data?.product?.name}`}
        content={`${product?.data?.product?.name} details page`}
      />
      {/* <!-- Product Details --> */}
      <section
        className="container mx-auto lg:px-20 px-2 my-6"
        id="product-section"
      >
        <div className="flex mx-auto justify-between items-start gap-4 xl:gap-10">
          <div className="xl:w-3/4">
            <div className="lg:flex mx-auto justify-center items-start gap-4 xl:gap-10">
              {/* <!-- product slider --> */}
              <div className="w-[350px] md:w-[400px] xl:w-1/2 mx-auto">
                <Swiper
                  navigation={true}
                  autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                  }}
                  modules={[Navigation, Autoplay]}
                  className="mySwiper"
                >
                  {ImageArray?.map((image) => (
                    <SwiperSlide key={image?.id}>
                      <img
                        src={image?.imageUrl}
                        alt={product?.data?.product?.name}
                        className="h-64 md:h-auto w-full md:w-auto"
                      />
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>

              {/* <!-- Product information --> */}
              <div className="xl:w-1/2" id="">
                <h2 className="text-xl lg:text-2xl font-semibold text-center lg:text-left">
                  {product?.data?.product?.name}
                </h2>
                <div className="text-xl lg:text-3xl my-4 flex justify-center lg:justify-start items-center gap-2 font-medium">
                  <div className="p-2">
                    {product?.data?.product.discount_price ? (
                      <p className="font-normal text-primary">
                        {product?.data?.product?.discount_price}TK.
                      </p>
                    ) : (
                      <p className="font-normal text-primary">
                        <span className="text-primary">
                          {product?.data?.product?.regular_price}TK.
                        </span>
                      </p>
                    )}
                  </div>
                  {product?.data?.product?.discount_price && (
                    <div className="flex items-center gap-1">
                      <del className="font-normal text-base_500">
                        {product?.data?.product?.regular_price}TK.
                      </del>
                    </div>
                  )}
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div
                    className="my-4 flex items-center justify-center md:justify-start gap-6"
                    id="buttonGroup"
                  >
                    <div className="flex flex-col">
                      <div className="flex flex-wrap justify-center md:justify-start items-center my-4 gap-4">
                        {colorsArr?.map(
                          (c) =>
                            c?.color !== null && (
                              <div key={c?.id}>
                                <input
                                  type="radio"
                                  id={c?.color}
                                  value={c?.color}
                                  {...register("colors")}
                                  className="hidden"
                                />
                                <label
                                  htmlFor={c?.color}
                                  className={`px-4 py-2 rounded-lg shadow-md transition-colors duration-300 ease-in-out transform hover:scale-105 cursor-pointer ${
                                    selectedColor === c?.color
                                      ? "bg-primary text-white border-2 border-primary"
                                      : "bg-base_200 text-gray-700 border border-gray-300"
                                  }`}
                                >
                                  {c?.color}
                                </label>
                              </div>
                            )
                        )}
                      </div>

                      <div className="flex flex-wrap justify-center md:justify-start items-center my-4 gap-4">
                        {sizeArr?.map(
                          (s) =>
                            s?.size !== null && (
                              <div>
                                <input
                                  type="radio"
                                  id={s?.size}
                                  value={s?.size}
                                  {...register("sizes")}
                                  className="hidden"
                                />
                                <label
                                  htmlFor={s?.size}
                                  className={`px-4 py-2 rounded-lg shadow-md transition-colors duration-300 ease-in-out transform hover:scale-105 cursor-pointer ${
                                    selectedColor === s?.size
                                      ? "bg-primary text-white border-2 border-primary"
                                      : "bg-base_200 text-gray-700 border border-gray-300"
                                  }`}
                                >
                                  {s?.size}
                                </label>
                              </div>
                            )
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="hidden md:block">
                    <div className="my-8 flex items-center justify-center md:justify-start gap-6 mx-auto text-xl font-md">
                      <div
                        className="rounded-full cursor-pointer"
                        type="button"
                      >
                        <CiCircleMinus
                          onClick={() => decreaseQuantity()}
                          className=" text-primary w-8 h-8"
                        />
                      </div>
                      <input
                        type="number"
                        value={quantity}
                        {...register("qty", { required: true })}
                        className="quantity-display border-primary border w-20 text-center py-1 rounded-lg"
                      />
                      <div
                        onClick={() => increaseQuantity()}
                        className="rounded-full cursor-pointer"
                        type="button"
                      >
                        <CiCirclePlus className=" text-primary w-8 h-8" />
                      </div>
                    </div>
                  </div>

                  {product?.data?.product?.qty > 0 ? (
                    <div className=" my-4 lg:flex items-center gap-6 font-medium">
                      {/* <div className="my-2 text-center">
                      <button className="rounded-md text-primary hover:text-danger">
                        <FaHeart className="w-6 h-6" />
                      </button>
                    </div> */}
                      <div className="my-2">
                        <button className="bg-primary hidden md:block w-full lg:px-4 py-2 rounded-md text-white">
                          Add to cart
                        </button>
                      </div>
                      <Link to="#" className="my-2">
                        <QuickOrder
                          data={quickData}
                          id={id}
                          discount_price={
                            product?.data?.product?.discount_price
                          }
                          regular_price={product?.data?.product?.regular_price}
                        />
                      </Link>
                    </div>
                  ) : (
                    <>
                      <div className="bg-primary text-xl lg:px-4 text-center py-2 my-2 rounded-md text-white">
                        Stock out
                      </div>
                    </>
                  )}
                </form>

                <div className="text-center md:text-left">
                  <a
                    href={`tel:${data?.generalData?.phone}`}
                    className="font-medium bg-primary text-white p-2 rounded-md w-full"
                  >
                    For Call :{data?.generalData?.phone}
                  </a>
                </div>
              </div>
            </div>

            {/* <!--Product details Tabs --> */}
            <div className="my-10 bg-white border border-base_200 rounded-xl">
              {/* <!-- Tab button --> */}
              <ul
                className="flex-shrink gap-5 border-b-2 md:flex px-10 py-2 mb-2 justify-start items-center text-sm font-medium text-center text-base_500"
                id="tabContainer"
              >
                <li className="my-1">
                  <button
                    className={`${
                      isOpenDescription === true
                        ? "bg-primary text-white"
                        : "bg-white"
                    } tab-btn inline-block px-1 lg:px-4 py-3   rounded-lg `}
                    onClick={() => tabButtonHandler("description")}
                  >
                    Description
                  </button>
                </li>
                <li className=" my-1">
                  <button
                    className={`${
                      isOpenReview === true
                        ? "bg-primary text-white"
                        : "bg-white"
                    } tab-btn inline-block px-1 lg:px-4 py-3   rounded-lg `}
                    onClick={() => tabButtonHandler("review")}
                  >
                    Review
                  </button>
                </li>
                <li className="my-1">
                  <button
                    className={`${
                      isOpenPolicy === true
                        ? "bg-primary text-white"
                        : "bg-white"
                    } tab-btn inline-block px-1 lg:px-4 py-3   rounded-lg `}
                    onClick={() => tabButtonHandler("policy")}
                  >
                    Product Policy
                  </button>
                </li>
                <li className="my-1">
                  <button
                    className={`${
                      isOpenVideo === true
                        ? "bg-primary text-white"
                        : "bg-white"
                    } tab-btn inline-block px-1 lg:px-4 py-3   rounded-lg `}
                    onClick={() => tabButtonHandler("video")}
                  >
                    See Video
                  </button>
                </li>
              </ul>

              {/* description */}
              {isOpenDescription === true && (
                <div className="px-2  lg:px-10 my-4">
                  <div dangerouslySetInnerHTML={{ __html: description }} />
                </div>
              )}
              {/* review */}
              {isOpenReview === true && (
                <div className="">
                  <div className="px-2 lg:px-10 my-4">
                    {product?.data?.product?.reviews ? (
                      product?.data?.product?.reviews?.map((review) => (
                        <div className="bg-base_200 p-2 lg:p-4 gap-4 mx-auto mb-4 rounded-md">
                          <div className="flex items-center gap-2 ">
                            <img
                              src="/images/avatar.avif"
                              alt=""
                              className="w-12 lg:w-12 rounded-full"
                            />
                            <h4>{review?.name}</h4>
                          </div>
                          <div>
                            <p>{review?.message}</p>
                            <div className="flex items-center gap-2">
                              {review?.rating >= 1 ? (
                                <FaStar className="text-danger" />
                              ) : (
                                <FaStar className="text-base_400" />
                              )}
                              {review?.rating >= 2 ? (
                                <FaStar className="text-danger" />
                              ) : (
                                <FaStar className="text-base_400" />
                              )}
                              {review?.rating >= 3 ? (
                                <FaStar className="text-danger" />
                              ) : (
                                <FaStar className="text-base_400" />
                              )}
                              {review?.rating >= 4 ? (
                                <FaStar className="text-danger" />
                              ) : (
                                <FaStar className="text-base_400" />
                              )}
                              {review?.rating >= 5 ? (
                                <FaStar className="text-danger" />
                              ) : (
                                <FaStar className="text-base_400" />
                              )}
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <p>There is no Review</p>
                    )}
                  </div>
                </div>
              )}
              {/*privacy */}
              {isOpenPolicy === true && (
                <div className="px-2 lg:px-10 my-4">
                  <div dangerouslySetInnerHTML={{ __html: policy }} />
                </div>
              )}
              {/*video*/}
              {isOpenVideo === true && (
                <div className="px-2 lg:px-10 my-4">
                  {product?.data?.product?.video_link ? (
                    <div className="mt-5 mx-auto flex justify-center items-center">
                      <iframe
                        className="w-[340px] h-[200px] lg:w-[700px] lg:h-[400px]"
                        src={product?.data?.product?.video_link}
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerPolicy="strict-origin-when-cross-origin"
                        allowFullScreen
                      ></iframe>
                    </div>
                  ) : (
                    "There is no video"
                  )}
                </div>
              )}
            </div>

            {/* Recently viewed products */}
            {/* <div className="mt-12">
              <h2 className="bg-white text-xl rounded-md font-medium p-2">
                Recently viewed
              </h2>
              <div className="grid grid-cols-1 lg:grid-cols-3 items-center">
                <div className="bg-white rounded-lg border hover:shadow-lg my-2 px-2">
                  <Link to="/pages/product.html">
                    <img
                      src="/images/airpods.jpg"
                      alt=""
                      className="mx-auto w-32 md:w-52"
                    />
                    <div>
                      <h5 className="font-medium text-sm tracking-tight text-black">
                        Liqua Vape Disposable Electric Device 10ML flavor for
                        wonderful quality
                      </h5>
                      <div className="flex items-center gap-5 mt-2">
                        <p className="font-normal text-primary">400 TK</p>
                        <del className="font-normal text-base_400">500 TK</del>
                        <br />
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="bg-white rounded-lg border hover:shadow-lg my-2 px-2">
                  <Link to="/pages/product.html">
                    <img
                      src="/images/airpods.jpg"
                      alt=""
                      className="mx-auto w-32 md:w-52"
                    />
                    <div>
                      <h5 className="font-medium text-sm tracking-tight text-black">
                        Liqua Vape Disposable Electric Device 10ML flavor for
                        wonderful quality
                      </h5>
                      <div className="flex items-center gap-5 mt-2">
                        <p className="font-normal text-primary">400 TK</p>
                        <del className="font-normal text-base_400">500 TK</del>
                        <br />
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="bg-white rounded-lg border hover:shadow-lg my-2 px-2">
                  <Link to="/pages/product.html">
                    <img
                      src="/images/airpods.jpg"
                      alt=""
                      className="mx-auto w-32 md:w-52"
                    />
                    <div>
                      <h5 className="font-medium text-sm tracking-tight text-black">
                        Liqua Vape Disposable Electric Device 10ML flavor for
                        wonderful quality
                      </h5>
                      <div className="flex items-center gap-5 mt-2">
                        <p className="font-normal text-primary">400 TK</p>
                        <del className="font-normal text-base_400">500 TK</del>
                        <br />
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="bg-white rounded-lg border hover:shadow-lg my-2 px-2">
                  <Link to="/pages/product.html">
                    <img
                      src="/images/airpods.jpg"
                      alt=""
                      className="mx-auto w-32 md:w-52"
                    />
                    <div>
                      <h5 className="font-medium text-sm tracking-tight text-black">
                        Liqua Vape Disposable Electric Device 10ML flavor for
                        wonderful quality
                      </h5>
                      <div className="flex items-center gap-5 mt-2">
                        <p className="font-normal text-primary">400 TK</p>
                        <del className="font-normal text-base_400">500 TK</del>
                        <br />
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="bg-white rounded-lg border hover:shadow-lg my-2 px-2">
                  <Link to="/pages/product.html">
                    <img
                      src="/images/airpods.jpg"
                      alt=""
                      className="mx-auto w-32 md:w-52"
                    />
                    <div>
                      <h5 className="font-medium text-sm tracking-tight text-black">
                        Liqua Vape Disposable Electric Device 10ML flavor for
                        wonderful quality
                      </h5>
                      <div className="flex items-center gap-5 mt-2">
                        <p className="font-normal text-primary">400 TK</p>
                        <del className="font-normal text-base_400">500 TK</del>
                        <br />
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="bg-white rounded-lg border hover:shadow-lg my-2 px-2">
                  <Link to="/pages/product.html">
                    <img
                      src="/images/airpods.jpg"
                      alt=""
                      className="mx-auto w-32 md:w-52"
                    />
                    <div>
                      <h5 className="font-medium text-sm tracking-tight text-black">
                        Liqua Vape Disposable Electric Device 10ML flavor for
                        wonderful quality
                      </h5>
                      <div className="flex items-center gap-5 mt-2">
                        <p className="font-normal text-primary">400 TK</p>
                        <del className="font-normal text-base_400">500 TK</del>
                        <br />
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div> */}
          </div>

          {/* <!-- Category and related product--> */}
          <div className="flex-col w-60 hidden md:block">
            <div className="relative w-60">
              <div
                onMouseLeave={hideDropdown}
                className="w-full hidden md:block overflow-y-scroll px-2 overflow-x-hidden rounded-lg bg-white h-60 xl:min-h-80 2xl:h-96"
              >
                <p className="p-2 text-xl text-center rounded-md font-medium">
                  Category
                </p>
                <ul className="">
                  {isCategoryLoading && <CategoriesDropdownSkeletonLoader />}
                  {Categories?.data?.map((c) => (
                    <div key={c?.id} onMouseEnter={() => showDropdown(c?.name)}>
                      <button
                        id="dropdownRightButton"
                        data-dropdown-trigger="hover"
                        data-dropdown-toggle={`${c?.name}`}
                        data-dropdown-placement="right"
                        className="text-black flex p-1 gap-4 items-center text-left my-2 hover:text-primary"
                      >
                        <span>
                          <img
                            src={c?.imageUrl}
                            alt={c?.name}
                            className="w-8 md:w-10"
                          />
                        </span>
                        <p className="w-32">{c?.name}</p>
                        <svg
                          className="w-2.5 h-2.5"
                          aria-hidden={true}
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 6 10"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="m1 9 4-4-4-4"
                          />
                        </svg>
                      </button>
                      {/* <!-- dropdown menu --> */}
                      <div
                        id={`${c?.name}`}
                        className={`${
                          visibleDropdown === c?.name ? "block" : "hidden"
                        } bg-white rounded-lg shadow py-2 px-4 absolute right-full xl:left-full top-0 w-52 z-20 h-60 xl:h-80 2xl:h-96`}
                      >
                        <ul className="py-2 text-base_700 px-4">
                          {c?.subcategories?.map((sub) => (
                            <li key={sub.id} className="my-2">
                              <Link
                                to={`/categories-products/${sub?.name}/${sub.id}`}
                              >
                                {sub.name}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  ))}
                </ul>
              </div>
            </div>
            {/* related product */}
            {product?.data?.related?.length > 0 && (
              <div className="mt-12">
                <h2 className="bg-white text-xl rounded-md font-medium p-2">
                  Related Products
                </h2>
                {product?.data?.related?.map((r) => (
                  <div
                    key={r?.id}
                    className="bg-white rounded-lg border hover:shadow-lg my-2 "
                  >
                    <Link
                      to={
                        r?.is_variable > 0
                          ? `/variableProduct/${r?.id}`
                          : `/product/${r?.id}`
                      }
                    >
                      <img
                        src={r?.imageUrl}
                        alt={r?.name}
                        className="mx-auto w-32 md:w-52 my-2 rounded-md"
                      />
                      <div className="px-2">
                        <h5 className="font-medium text-sm tracking-tight text-black">
                          {r?.name.slice(0, 25)}...
                        </h5>
                        <div className="p-2">
                          {r?.discount_price ? (
                            <p className="font-normal text-primary">
                              {r?.discount_price}TK.
                            </p>
                          ) : (
                            <p className="font-normal text-primary">
                              <span className="text-primary">
                                {r?.regular_price}TK.
                              </span>
                            </p>
                          )}
                          {r?.discount_price && (
                            <div className="flex items-center gap-1">
                              <del className="font-normal text-base_400">
                                {r?.regular_price}TK.
                              </del>
                            </div>
                          )}
                        </div>
                      </div>
                    </Link>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </section>
    </div>
  );
};

export default SingleProduct;
