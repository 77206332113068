import { createBrowserRouter } from "react-router-dom";
import MainLayout from "../layout/main-layout";
import Home from "../pages/home";
import About from "../pages/about";
import SuccessOrder from "../pages/success-order";
import Privacy from "../pages/policy/privacy";
import Payment from "../pages/policy/payment";
import Refund from "../pages/policy/refund";
import TermsCondition from "../pages/policy/terms-condition";
import CategoryPage from "../pages/category";
import ViewCart from "../pages/view-cart";
import Brands from "../pages/brands";
import Checkout from "../pages/checkout";
import ContactUs from "../pages/contact-us";
import SingleProduct from "../pages/singleProduct";
import FilterProducts from "../pages/filter-products";
import VariableProduct from "../pages/variableProduct";
import CategoriesProducts from "../pages/categories-products";
import OfferProducts from "../pages/offer-products";
import SearchProducts from "../pages/searchProducts";

// all routes here
const router = createBrowserRouter([
  {
    path: "/",
    element: <MainLayout />,
    errorElement: "This is error",
    children: [
      {
        path: "/",
        element: <Home></Home>,
      },
      {
        path: "/all-products/:name",
        element: <FilterProducts />,
      },
      {
        path: "/all-products/:name/:id",
        element: <FilterProducts />,
      },
      {
        path: "/categories-products/:name/:id",
        element: <CategoriesProducts />,
      },
      {
        path: "/product/:id",
        element: <SingleProduct />,
      },
      {
        path: "/offer-products/:slug",
        element: <OfferProducts />,
      },
      {
        path: "/variableProduct/:id",
        element: <VariableProduct />,
      },
      {
        path: "/brands",
        element: <Brands />,
      },
      {
        path: "/categories",
        element: <CategoryPage />,
      },
      {
        path: "/view-cart",
        element: <ViewCart />,
      },
      {
        path: "/checkout",
        element: <Checkout />,
      },
      {
        path: "/about-us",
        element: <About />,
      },
      {
        path: "/contact-us",
        element: <ContactUs />,
      },
      {
        path: "/success-order/:orderId",
        element: <SuccessOrder />,
      },
      {
        path: "/privacy",
        element: <Privacy />,
      },
      {
        path: "/payment",
        element: <Payment />,
      },
      {
        path: "/refund",
        element: <Refund />,
      },
      {
        path: "/terms-condition",
        element: <TermsCondition />,
      },
      {
        path: "/search-products/:searchName",
        element: <SearchProducts />,
      },
    ],
  },
]);

export default router;
