import axios from "axios";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  useCartDeleteMutation,
  useGetCartProductsQuery,
  useGetTotalCartQuery,
  usePlaceOrderMutation,
} from "../redux/feature/slice/service-api";
import { FaRegTrashAlt } from "react-icons/fa";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";
import Title from "../components/helmet/title";
const Checkout = () => {
  const [ip, setIp] = useState();
  const navigate = useNavigate();
  const { data: cart, refetch } = useGetCartProductsQuery(ip);
  useEffect(() => {
    window.scrollTo(0, 0);

    const fetchIpAddress = async () => {
      try {
        const response = await axios.get("https://api.ipify.org?format=json");
        setIp(response.data.ip);
      } catch (error) {
        console.error("Error fetching IP address:", error);
        setIp("127.0.0.1");
      }
    };
    fetchIpAddress();
  }, [refetch]);

  //Datalayer Code...
  useEffect(() => {
    if (cart?.data?.carts && Array.isArray(cart.data.carts)) {
      const items = cart.data.carts.map(cartItem => ({
        item_name: cartItem.product.name,
        item_id: cartItem.product.id,
        price: cartItem.product.discount_price || cartItem.product.regular_price,
        item_brand: "Unknown",
        item_category: cartItem.product.category.name,
        item_variant: "",
        item_list_name: "",
        item_list_id: "",
        index: 0,
        quantity: cartItem.qty,
      }));

      const total_price = cart.data.carts.price;
      
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({ ecommerce: null }); // Clear previous ecommerce object to avoid data leakage
      window.dataLayer.push({
        event: "begin_checkout",
        ecommerce: {
          currency: "BDT",
          value: total_price,
          items: items,
        },
      });
    } else {
      console.error('Cart data is not an array:', cart);
    }
  }, [cart]);
  //Datalayer Code...

  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      delivery: "100", // default value for the radio button
    },
  });

  const deliveryValue = watch("delivery"); // watch the delivery field

  const handleDeliveryChange = (event) => {
    setValue("delivery", event.target.value); // set the value of the delivery field
  };
  // place order mutation
  const [confirmOrder] = usePlaceOrderMutation();

  // products details
  const productsDetails = cart?.data?.carts
    ? cart?.data?.carts?.map((c) => ({
        id: c?.product?.id,
        color: c?.color ? c?.color : null,
        price: c?.price,
        qty: c?.qty,
        size: c?.size ? c?.size : null,
      }))
    : [];

  // Total cards quantity
  const TotalQuantity = cart?.data?.carts
    ? cart?.data?.carts
        ?.reduce((total, currentProduct) => total + currentProduct.qty, 0)
        .toString()
    : 0;

  const onSubmit = async (data) => {
    const subTotal = (cart?.data?.subTotal + Number(deliveryValue)).toString();

    const order = {
      ip_address: ip,
      customer_name: data.name,
      customer_phone: data.phone,
      delivery_area: data.delivery,
      customer_address: data.address,
      price: subTotal,
      product_quantity: TotalQuantity,
      payment_type: "cod",
      order_type: "Website",
      products: productsDetails,
    };
    if (productsDetails.length > 0) {
      try {
        console.log(order);
        const res = await confirmOrder(order);
        if (res?.data?.success === true) {
          const orderId = res?.data?.data?.orderId;
          refetch();
          countRefetch();
          navigate(`/success-order/${orderId}`);
        }
      } catch (error) {
        // console.log(error);
      }
    } else {
      swal({
        title: "Please add to cart products.",
        icon: "error",
      });
    }
    reset();
  };

  const { refetch: countRefetch } = useGetTotalCartQuery(ip);

  const [deleteCart] = useCartDeleteMutation();

  // carts delete handler
  const deleteHandler = async (id) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this product!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        try {
          const res = await deleteCart(id);
          swal("Good job!", res?.data?.message, "success");
          refetch();
          countRefetch();
        } catch (error) {
          console.log(error);
        }
      } else {
        swal("Your product is safe!");
      }
    });
  };

  return (
    // <!-- Checkout section -->
    <section className="container mx-auto px-2 lg:px-20 my-10 lg:py-20 mt-14 md:mt-0">
      <Title title={"Checkout"} content={"This is checkout page"} />
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="lg:flex justify-between items-start gap-5 mx-auto min-h-full"
        id="checkout_form"
      >
        <div className="w-full xl:w-2/3 rounded-md bg-white shadow-xl py-2 lg:py-10 px-2 lg:px-6 my-6 lg:my-0">
          <h3 className="text-xl lg:text-2xl font-semibold py-2">
            Billing / Shipping Details
          </h3>
          <div className="lg:flex justify-between items-center gap-4 my-4">
            <div className="w-full">
              <input
                type="text"
                name="name"
                placeholder="Enter full Name*"
                className="bg-base lg:py-4 w-full border border-base_300 text-dark rounded-lg focus:ring-primary focus:border-primary block p-2.5"
                {...register("name", { required: true })}
                aria-invalid={errors.name ? "true" : "false"}
              />
              <p className="py-1">
                {errors.name?.type === "required" && (
                  <small role="alert" className="text-danger">
                    name is required
                  </small>
                )}
              </p>
            </div>
            <div className="w-full">
              <input
                type="tel"
                name="phone"
                placeholder="Phone*"
                className="bg-base w-full lg:py-4 border border-base_300 text-dark rounded-lg focus:ring-primary focus:border-primary block p-2.5"
                {...register("phone", { required: true })}
              />
              <p className="py-1">
                {errors.phone?.type === "required" && (
                  <small role="alert" className="text-danger">
                    Phone is required
                  </small>
                )}
              </p>
            </div>
          </div>
          <div className="my-4">
            <textarea
              name="address"
              cols="30"
              rows="5"
              placeholder="Enter your full Address*"
              className="bg-base w-full lg:py-4 border border-base_300 text-dark rounded-lg focus:ring-primary focus:border-primary block p-2.5"
              {...register("address", { required: true })}
              aria-invalid={errors.address ? "true" : "false"}
            ></textarea>
            <p className="py-1">
              {errors.address?.type === "required" && (
                <small role="alert" className="text-danger">
                  Address is required
                </small>
              )}
            </p>
          </div>
          <div className="my-4">
            <div className="mb-2">
              <input
                type="radio"
                value="80"
                name="delivery"
                id="inside"
                className="defaultChecked"
                {...register("delivery", { required: true })}
                defaultChecked
                onChange={handleDeliveryChange}
              />
              <label htmlFor="inside">Inside Dhaka(80 TK.)</label>
            </div>
            <div>
              <input
                type="radio"
                name="delivery"
                value="150"
                id="outside"
                {...register("delivery", { required: true })}
                onChange={handleDeliveryChange}
              />
              <label htmlFor="outside">Outside Dhaka(150 TK.)</label>
            </div>
          </div>
        </div>
        <div className="w-full xl:w-1/3 bg-white rounded-md shadow-xl p-2 lg:p-6 h-fit">
          <div className="overflow-y-scroll h-[200px]">
            {cart?.data?.carts?.length > 0 ? (
              <>
                {cart?.data?.carts?.map((cProducts) => (
                  <div
                    className="flex justify-between items-center mx-auto gap-4 my-4"
                    key={cProducts?.id}
                  >
                    <div>
                      <img
                        src={cProducts?.product?.imageUrl}
                        alt={cProducts?.product?.name}
                        className="w-20"
                      />
                    </div>
                    <div className="w-72">
                      <h4 className="block lg:hidden">
                        {cProducts?.product?.name.slice(0, 25)}...
                      </h4>
                      <h4 className="hidden lg:block">
                        {cProducts?.product?.name}
                      </h4>
                      <div>
                        <p className="text-primary font-medium">
                          {cProducts?.price * cProducts?.qty} TK.
                        </p>
                        <p>{cProducts?.qty} Item</p>
                        <div className="flex items-center gap-2 mx-auto">
                          <p>
                            Size:{" "}
                            {cProducts?.size ? cProducts?.size : "No size"}
                          </p>
                          <p>
                            Color:
                            {cProducts?.color ? cProducts?.color : "No color"}
                          </p>
                        </div>
                      </div>
                    </div>
                    <button
                      onClick={() => deleteHandler(cProducts?.id)}
                      className="text-primary active:text-danger"
                    >
                      <FaRegTrashAlt />
                    </button>
                  </div>
                ))}
              </>
            ) : (
              "You haven't added any product!"
            )}
          </div>

          <hr className="border my-2" />
          <div>
            <div className="flex justify-between my-1 items-center">
              <p className="font-semibold">Sub Total</p>
              <p>{cart?.data?.subTotal} Tk.</p>
            </div>
            <div className="flex justify-between my-1 items-center">
              <p className="font-semibold">Delivery Charge</p>
              <p>{deliveryValue} Tk.</p>
            </div>
            <hr className="border my-2" />
            <div className="flex justify-between items-center">
              <p className="font-semibold">Grand Total</p>
              <p>{cart?.data?.subTotal + Number(deliveryValue)} Tk.</p>
            </div>
            <div className="mt-4">
              <div>
                <p className="my-1 font-semibold">Select Payment Method</p>

                <input
                  type="radio"
                  name="paymentMethod"
                  id=""
                  value="Cash on Delivery"
                  defaultChecked
                />
                <label htmlFor="paymentMethod">Cash on delivery</label>
              </div>
              <div>
                <button className="bg-primary py-2 w-full mt-6 text-white rounded-md">
                  Place an Order{" "}
                  <i className="fa-solid fa-right-from-bracket"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </section>
  );
};

export default Checkout;
