import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  useCartDeleteMutation,
  useGetCartProductsQuery,
  useGetTotalCartQuery,
} from "../redux/feature/slice/service-api";
import { FaRegTrashAlt } from "react-icons/fa";
import swal from "sweetalert";
import Title from "../components/helmet/title";

const ViewCart = () => {
  const [ip, setIp] = useState();
  useEffect(() => {
    window.scrollTo(0, 0);

    const fetchIpAddress = async () => {
      try {
        const response = await axios.get("https://api.ipify.org?format=json");
        setIp(response.data.ip);
      } catch (error) {
        console.error("Error fetching IP address:", error);
        setIp("127.0.0.1");
      }
    };
    fetchIpAddress();
  }, []);

  const { refetch } = useGetTotalCartQuery(ip);

  const { data, refetch: refetchCart } = useGetCartProductsQuery(ip);
  const [deleteCart] = useCartDeleteMutation();

 //Datalayer Code...
 useEffect(() => {
  if (data?.data?.carts && Array.isArray(data.data.carts)) {
    const items = data.data.carts.map(cartItem => ({
      item_name: cartItem.product.name,
      item_id: cartItem.product.id,
      price: cartItem.product.discount_price || cartItem.product.regular_price,
      item_brand: "Unknown",
      item_category: cartItem.product.category.name,
      item_variant: "",
      item_list_name: "",
      item_list_id: "",
      index: 0,
      quantity: cartItem.qty,
    }));

    const total_price = data.data.carts.price;

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: "view_cart",
      ecommerce: {
        currency: "BDT",
        value: total_price,
        items: items,
      },
    });
  } else {
    console.error('Cart data is not an array:', data);
  }
}, [data]);
//Datalayer Code...

  const deleteHandler = async (id) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this product!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        try {
          const res = await deleteCart(id);
          swal("Good job!", res?.data?.message, "success");
          refetch();
          refetchCart();
        } catch (error) {
          console.log(error);
        }
      } else {
        swal("Your product is safe!");
      }
    });
  };

  return (
    <>
      <section className="container mx-auto px-2 lg:px-20 my-10 mt-14 md:mt-10">
        <Title title={"View cart"} content={"This is view cart page"} />
        <div>
          <button className="px-4 py-4 bg-primary text-white rounded-md hover:bg-green-700">
            <Link to="/">Continue Shopping</Link>
          </button>
        </div>
        {/* <!-- Table --> */}
        <div>
          <div className="relative overflow-x-auto mt-10">
            <table className="w-full text-sm text-left rtl:text-right text-base_500">
              <thead className="text-md text-base_700 uppercase bg-base">
                <tr>
                  <th scope="col" className="px-4 py-4">
                    Image
                  </th>
                  <th scope="col" className="py-4">
                    Product Name
                  </th>
                  <th scope="col" className="p-4">
                    Price
                  </th>
                  <th scope="col" className="px-4 py-4">
                    Quantity
                  </th>
                  <th scope="col" className="p-4">
                    Total
                  </th>
                  <th scope="col" className="p-2">
                    Remove
                  </th>
                </tr>
              </thead>
              <tbody>
                {data?.data?.carts?.length > 0 && (
                  <>
                    {data?.data?.carts?.map((cProduct) => (
                      <tr
                        className="text-black border-b bg-base_300"
                        key={cProduct?.id}
                      >
                        <td className="px-4 py-2">
                          <img
                            src={cProduct?.product?.imageUrl}
                            alt=""
                            className="w-20 h-20"
                          />
                        </td>
                        <td className="p-2 font-medium text-md">
                          <h2 className="w-44 lg:w-96">
                            {cProduct?.product?.name}
                          </h2>
                        </td>
                        <td className="px-4 py-2 font-medium whitespace-nowrap text-md">
                          {cProduct?.price}
                        </td>
                        <td className="font-medium whitespace-nowrap">
                          <p className="py-2 w-24 border border-primary text-center rounded-md text-md">
                            {cProduct?.qty}
                          </p>
                        </td>
                        <td className="px-4 py-2 font-medium whitespace-nowrap text-md">
                          <p className="">{cProduct?.price * cProduct?.qty}</p>
                        </td>
                        <td className="px-6 py-2 text-center">
                          <button
                            onClick={() => deleteHandler(cProduct?.id)}
                            className="text-primary active:text-danger"
                          >
                            <FaRegTrashAlt />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </>
                )}
              </tbody>
            </table>
            {data?.data?.carts.length <= 0 && (
              <div className="my-10">
                <p className="text-center">There is no products</p>
              </div>
            )}
          </div>
          <div className="text-center my-8">
            <button className="bg-primary rounded-md px-8 py-4 text-white">
              <Link to="/checkout">
                Proceed to checkout
                <i className="fa-solid fa-right-from-bracket"></i>
              </Link>
            </button>
          </div>
        </div>
      </section>
    </>
  );
};

export default ViewCart;
